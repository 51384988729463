<template>
  <b-container fluid="">
    <b-row>
      <b-col sm="12" lg="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Tambah User Login</h4>
          </template>
          <template v-slot:body>
            <form >
              <div class="form-row">
                <b-col md="12" class="mb-3">
                  <label for="email">Nama Pegawai *</label>
                  <v-select :options="opt" :reduce="id => id.code" v-model="form.email" class="style-chooser"></v-select>
                </b-col>
                <b-col md="12" class="mb-3">
                  <label for="full_name">Password *</label>
                  <input type="text" class="form-control" id="full_name" v-model="form.password">
                </b-col>
                <b-col md="12" class="mb-3">
                  <label for="name">Peran *</label>
                  <v-select :options="role" v-model="form.role" class="style-chooser"></v-select>
                </b-col>
              </div>
              <div class="float-right">
                <button type="submit" class="btn bg-danger mr-1" @click.prevent="formCancel()">Batal</button>
                <button type="submit" class="btn btn-primary" @click.prevent="formSave()">Simpan</button>
              </div>
            </form>
          </template>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { getDataService, postDataService } from '../../../store/modules/crudservices'

export default {
  name:'UserAdd',
  data() {
    return {
      form:{
        email: null,
        role: null,
        password: null
      },
      opt: [],
      role: []
    }
  },
  components:{
    
  },
  methods: {
    getPegawai() {
      const url = '/pegawai-search?filter=user';
      getDataService(url).then(response => {
        this.opt = response.data
      })
    },
    getRole() {
      const url = '/role-grid';
      getDataService(url).then(response => {
        this.role = response.data.map((data)=> data.name )
      })
    },
    formSave(){
      const form = this.form
			const _data = {
				'email': form.email,
				'role': form.role,
				'password': form.password
			}
      
      postDataService('/user', _data).then(response => {
        if(response.data.success) this.$router.push('/config/user')
      })
    },
    formCancel(){
      this.$router.push('/config/user') 
    },
  },
  mounted() {
    this.getPegawai()
    this.getRole()
  }
}
</script>